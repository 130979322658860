import axios from "axios";
import history from "../history";

import {
  carsUrl,
  citiesUrl,
  dealersUrl,
  resultFormUrl,
  callbackFormUrl,
  settingsUrl,
} from "../api/endpoints";

export const fetchCars = () => async (dispatch) => {
  const response = await axios.get(`${carsUrl}`);
  dispatch({ type: "FETCH_CARS", payload: response.data });
};

export const fetchCities = (data) => {
  let selectedCarID = data.id;
  return async (dispatch) => {
    try {
      const response = await axios.get(`${citiesUrl}${selectedCarID}`);
      dispatch({ type: "FETCH_CITIES", payload: response.data });
      history.push("/select-city");
    } catch (err) {
      console.error(`Mistake is happen`, err);
    }
  };
};

export const fetchTimePlace = (city, car) => {
  let selectedCarID = car.id;
  let selectedCityID = city.id;
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${dealersUrl}${selectedCarID}/${selectedCityID}`
      );
      dispatch({ type: "FETCH_DEALERS", payload: response.data });
      history.push("/select-time-place");
    } catch (err) {
      console.error(`Mistake is happen`, err);
    }
  };
};

export const sendResultForm = (data) => {
  return async (dispatch) => {
    try {
      let host = window.location.host;
      const response = await axios.get(`${resultFormUrl}${data}&host=${host}`);
      console.log(response);
      if (!!response.data.success) {
        dispatch({ type: "SEND_RESULT", payload: response.success });
        history.push("/thank-you");
      } else {
        console.error(`Mistake is happen`, "No booking for these dates");
      }
      return response;
    } catch (err) {
      console.error(`Mistake is happen`, err);
    }
  };
};

export const callBackForm = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${callbackFormUrl}${data}&dealerSite=0`);
      dispatch({ type: "CALLBACK_FORM", payload: response.status });
    } catch (err) {
      console.error(`Mistake is happen`, err);
    }
  };
};

export const fetchSettings = () => async (dispatch) => {
  try {
    const response = await axios.get(`${settingsUrl}`);
    dispatch({ type: "FETCH_SETTINGS", payload: response.data });
  } catch (err) {
    console.error(`Mistake is happen`, err);
  }
}

export const selectCar = (car) => {
  return {
    type: "CAR_SELECTED",
    payload: car,
  };
};

export const selectCity = (city) => {
  return {
    type: "CITY_SELECTED",
    payload: city,
  };
};

export const selectPage = (page) => {
  return {
    type: "PAGE_SELECTED",
    payload: page,
  };
};

export const selectActivePage = (page) => {
  return {
    type: "ACTIVE_PAGE",
    payload: page,
  };
};

export const selectIndex = (index) => {
  return {
    type: "INDEX_SELECTED",
    payload: index,
  };
};

export const selectDate = (date) => {
  return {
    type: "DATE_SELECTED",
    payload: date,
  };
};
export const selectTime = (time) => {
  return {
    type: "TIME_SELECTED",
    payload: time,
  };
};

export const selectCoords = (coords) => {
  return {
    type: "COORDS_SELECTED",
    payload: coords,
  };
};

export const selectDealer = (dealer) => {
  return {
    type: "DEALER_SELECTED",
    payload: dealer,
  };
};

export const selectUrlParam = (urlParam) => {
  return {
    type: "URL_PARAM_SELECTED",
    payload: urlParam,
  };
};
