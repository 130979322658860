import React, { Suspense, useEffect } from 'react';
import './App.css';
import { fetchSettings } from './actions';
import { useDispatch, useSelector } from 'react-redux';

const Layout = React.lazy(() => import('./pages/Layout'));
const queryParams = new URLSearchParams(window.location.search);
const lang = queryParams.get('lang');

const App = () => {
    const settings = useSelector(state => state.settings);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSettings());
    }, [])

    if (!settings.language) {
        return <div>Getting settings, please wait...</div>
    }

    return <div>
    <Suspense fallback={<div>Loading...</div>}>
        <Layout lang={lang} />
    </Suspense>
</div>
}



export default App;
