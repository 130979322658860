let baseUrl = '/api';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  baseUrl = 'http://localhost:3000/api';
}

// step 1
export const carsUrl = `${baseUrl}/v1/model/list/`;

// step 2
export const citiesUrl = `${baseUrl}/v1/city/model/`;

// step 3
//https://stage.api.td.renault.ua/car/list/2/5
export const dealersUrl = `${baseUrl}/v1/car/list/`;

// step 4
//https://stage.api.td.renault.ua/car/list/2/5
export const resultFormUrl = `${baseUrl}/v1/setBooking?`;

// callback form
//https://stage.api.td.renault.ua/car/list/2/5
export const callbackFormUrl = `${baseUrl}/v1/callback?`;

// settings
export const settingsUrl = `${baseUrl}/v2/settings-form`;


